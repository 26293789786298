import React from "react";

import GoogleIcon from "./GoogleIcon";
import FacebookIcon from "./FacebookIcon";
import TwitterIcon from "./TwitterIcon";

const SocialLoginButton = ({ onClick, buttonText, provider }) => {
  let providerImage = <GoogleIcon />;

  if (provider === "facebook") providerImage = <FacebookIcon />;
  if (provider === "twitter") providerImage = <TwitterIcon />;

  return (
    <div className="mx-2 h-10 w-60 ">
      <button
        onClick={onClick}
        className="px-2 h-10 w-full "
        style={{
          backgroundColor: "#fff",
          display: "inline-flex",
          color: "rgba(0, 0, 0, .54)",
          borderRadius: 4,
          border: "1px solid",
          fontSize: 14,
          fontWeight: "500",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        {providerImage}
        <span
          className="flex items-center m-auto justify-center"
          style={{
            paddingRight: 10,
            fontWeight: 500,
            paddingLeft: 0,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {buttonText}
        </span>
      </button>
    </div>
  );
};

export default SocialLoginButton;
